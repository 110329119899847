import React, { useState } from 'react';
import { navigate } from 'gatsby-link';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import facebook from '../../img/social/facebook-o.svg';
import twitter from '../../img/social/twitter.svg';
import instagram from '../../img/social/instagram.svg';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const ContactUs = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({});

  const handleChange = e => {
    if (e.target.name !== 'agree') {
      return setFormState({ [e.target.name]: e.target.value });
    }
    return setFormState({ [e.target.name]: e.target.checked });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formState,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error));
  };

  return (
    <Layout>
      <div className="contact_us">
        <iframe src="https://maps.google.com/maps?t=m&amp;output=embed&amp;iwloc=near&amp;z=12&amp;q=1601-1699+Decatur+St%2C+Ridgewood%2C+NY+11385%2C+USA" />
      </div>
      <div className="container">
        <section className="section is-flex is-flex-direction-column px-6">
          <div className="columns is-mobile">
            <div className="column is-half is-offset-one-quarter">
              <h2 className="has-text-centered mx-auto mb-5 mt-6 is-size-3 has-text-weight-bold has-text-black">
                <strong>
                  {t('contact.how_to')}
                  <span className="has-background-link has-text-link-light">
                    {t('contact.find')}
                  </span>
                  {t('contact.us')}
                </strong>
              </h2>
              <p className="has-text-centered mx-auto mt-2 mb-6">
                {t('contact.work_benefit')}
              </p>
            </div>
          </div>
          <div className="columns">
            <div className="column is-4">
              <p className="mb-5">
                <span className="has-text-weight-bold">
                  {t('contact.address')}
                </span>
                <br />
                Chicago, IL 60606, 123, New Lenox
              </p>
              <p className="mb-5">
                <span className="has-text-weight-bold">
                  {t('contact.we_are_open')}
                </span>
                <br />
                {t('contact.open_hours')} 8.00-18.00 Mon-Sat
              </p>
              <p className="mb-5">
                <span className="has-text-weight-bold">
                  {t('contact.phone')}
                </span>
                <br />
                <a href="tel:123-456-7890">123-456-7890</a>,
                <a href="tel:123-456-7890">123-456-7890</a>
              </p>
              <p className="mb-5">
                <span className="has-text-weight-bold">
                  {t('contact.email')}
                </span>
                <br />
                <a href="mailto:info@yoursite.com">info@yoursite.com</a>
              </p>
              <div className="sc_socials">
                <a
                  className="mr-4"
                  title="facebook"
                  href="https://www.facebook.com/denis.rybalka.77"
                >
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a
                  className="mr-4"
                  title="twitter"
                  href="https://twitter.com/DenisRybalka"
                >
                  <img
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a
                  className="mr-4"
                  title="instagram"
                  href="https://www.instagram.com/denis_rybalka/"
                >
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div>
            <div className="column is-8">
              <form
                className="is-flex is-flex-direction-column"
                name="contact"
                method="post"
                action="/contact/thanks"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    <input
                      className="input"
                      placeholder={t('contact.your_name')}
                      type={'text'}
                      name={'name'}
                      onChange={handleChange}
                      id={'name'}
                      required={true}
                    />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    <input
                      className="input"
                      placeholder={t('contact.email')}
                      type={'email'}
                      name={'email'}
                      onChange={handleChange}
                      id={'email'}
                      required={true}
                    />
                  </label>
                </div>
                <div className="field">
                  <label htmlFor="your-subject" className="label">
                    <input
                      size="40"
                      className="input"
                      placeholder={t('contact.subject')}
                      type="text"
                      name={'your-subject'}
                    />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'message'}>
                    <textarea
                      className="textarea"
                      cols="40"
                      rows="10"
                      name={'message'}
                      placeholder={t('contact.message')}
                      onChange={handleChange}
                      id={'message'}
                      required={true}
                    />
                  </label>
                </div>
                <div className="field">
                  <div className="control">
                    <label className="checkbox" htmlFor={'agree'}>
                      <input
                        type="checkbox"
                        className="mr-1"
                        name={'agree'}
                        onChange={handleChange}
                      />
                      {t('contact.i_agree')}
                    </label>
                  </div>
                </div>
                <div className="control">
                  <button
                    className="button is-link py-5 px-6"
                    type="submit"
                    disabled={!formState.agree}
                  >
                    {t('contact.send')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ContactUs;
